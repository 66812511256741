<template>
  <div class="forgot">
    <div class="forgot_left">
      <Form ref="formInline" :model="formInline" :rules="ruleInline">
        <FormItem class="forgot_title">
          <span class="fs-40 main-font-color">{{brand_name}}</span><span class="fs30">重置登录密码</span>
        </FormItem>
        <FormItem label="手机号" prop="mobile">
          <i-input size="large" v-model="formInline.mobile" placeholder="请填写手机号"></i-input>
        </FormItem>

        <FormItem label="验证码" prop="code">
          <i-input size="large" v-model="formInline.code" placeholder="请填写验证码">
            <Button slot="append" @click="getCode" style="width:100px" :disabled="show">获取验证码</Button>
          </i-input>
        </FormItem>

        <FormItem>
          <Button type="primary" size="large" @click="handleSubmit('formInline')" class="submit-button">继续</Button>

          <Button type="text" class="color-grey ml-20 fs-16" @click="back()">返回</Button>

        </FormItem>
      </Form>
    </div>
  </div>
</template>

<script>
export default {
  name: "Forgot",
  data() {
    return {
      formInline: {
        mobile: '',
        code: ''
      },
      ruleInline: {
        mobile: [
          {required: true, message: '请填写手机号', trigger: 'blur'},
        ],
        code: [
          {required: true, message: '请填写验证码', trigger: 'blur'}
        ],
      },
      sendFlag: false,
      timer: null,
      show: false,
      brand_name: '禾晓',
    }
  },
  methods: {
    getCode(event) {
      let el = event.currentTarget;
      const TIME_COUNT = 60;
      if (this.formInline.mobile) {
        if (!this.timer) {
          this.count = TIME_COUNT;
          this.show = true;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count--;
              el.innerHTML = this.count;
            } else {
              this.show = false;
              clearInterval(this.timer);
              this.timer = null;
              el.innerHTML = "获取验证码";
            }
          }, 1000);

          //发送邮件

          let param = {
            mobile: this.formInline.mobile.trim(),
          };
          this.$axios
              .post('/api/user/sendForgotCodeByPhone', param)
              .then((response) => {
                if (response.data.err_no == 0) {
                  this.$Message.success('验证码发送成功，请注意查收！');
                } else {
                  this.$Message.error(response.data.err_msg);
                  this.show = false;
                  clearInterval(this.timer);
                  this.timer = null;
                  el.innerHTML = "获取验证码";
                }
              })
        }
      } else {
        this.$Message.error('请填写手机号！');
      }

    },
    handleSubmit(name) {
      let param = {
        mobile: this.formInline.mobile.trim(),
        code: this.formInline.code.trim(),
      };
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.$axios
              .post('/api/user/validForgotPhoneCode', param)
              .then((response) => {
                if (response.data.err_no == 0) {
                  this.$router.push({
                    name: 'resetPassword',
                    query: param
                  })
                } else {
                  this.$Message.error(response.data.err_msg);
                }
              })
        } else {
          // this.$Message.success('提交成功!');
          // this.$Message.error('表单验证失败!');
        }
      })
    },
    setBrandName() {
      let brand_name = localStorage.getItem('brand_name');
      console.log(brand_name)
      if ( brand_name && brand_name != undefined) {
        this.brand_name = brand_name;
        console.log(this.brand_name)
      };
    },
    back(){
      this.$router.go(-1);//返回上一层
    },
  },
  mounted() {
    this.setBrandName();
  }
}
</script>

<style scoped>
.forgot {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
}
.forgot_left {
  width: 400px;
  margin: 80px 100px;
  height: auto;
}

.submit-button {
  width: 200px;
  float: left;
}

.forgot_title {
  text-align: left;
  color: #657180;
}

.ivu-form .forgot_title .ivu-form-item-label {
  font-size: 20px !important;
}

.ivu-form-item {
  margin-bottom: 20px;
}

.ivu-radio-default {
  margin: 0 15px;
}
</style>
